import { createContext, useContext, useState, ReactNode } from 'react';

export interface IsShareDesignOpenContextType {
  isShareDesignOpen: boolean;
  setIsShareDesignOpen: (isOpen: boolean) => void;
}

const IsShareDesignOpenContext = createContext<
  IsShareDesignOpenContextType | undefined
>(undefined);

export function IsShareDesignOpenProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isShareDesignOpen, setIsShareDesignOpen] = useState<boolean>(
    false
  );
  return (
    <IsShareDesignOpenContext.Provider
      value={{ isShareDesignOpen, setIsShareDesignOpen }}
    >
      {children}
    </IsShareDesignOpenContext.Provider>
  );
}

export function useIsShareDesignOpen(): IsShareDesignOpenContextType {
  const context = useContext(IsShareDesignOpenContext);
  if (!context) {
    throw new Error(
      'useIsShareDesignOpen must be used within an IsShareDesignOpenProvider'
    );
  }
  return context;
}
