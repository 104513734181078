import joynconfig, { SofaFurnitureConfig } from './joyn-config';
import flatten from 'lodash/flatten';
import { Option } from 'src/types';
import { screenFactor } from '../utils/configUtils';
import nyceConfig from './nyce-config';
import Utils from '../utils/utils';

const baseCameraSetup = {
  i1: { angle: -30, vAngle: -15 },
  i2: { angle: -30, vAngle: -15 },
  i3: { angle: -30, vAngle: -15 },
  i4: { angle: -30, vAngle: -15 },
  L1: { angle: -45, vAngle: -35 },
  L2: { angle: 0, vAngle: -45 },
  L3: { angle: 0, vAngle: -45 },
  L4: { angle: 0, vAngle: -45 },
  U1: { angle: 0, vAngle: -45 },
  U2: { angle: 0, vAngle: -45 },
  U3: { angle: 0, vAngle: -45 },
  U4: { angle: 0, vAngle: -45 },
};

const reviewCameraSetup = Utils.cloneDeep(baseCameraSetup);
Object.keys(reviewCameraSetup).forEach(
  (pattern) => (reviewCameraSetup[pattern].vAngle = -15)
);

/**
 * Pyllow Sofa Config
 */
const config: SofaFurnitureConfig = {
  hasScene: false,
  scenesOptions: [],
  hasAssembly: true,
  hasViewInterior: false,
  showRelatedProduct: false,
  noBackgroundCarousel: false,
  translationDimensionMapping: true,
  defaultArmrestColor: 'backCushion',
  defaultArmrestLength: 480,
  laminateMapping: {},
  lacquerMapping: {},
  colors: {},
  default: {
    modules: [
      {
        armrestLength: [0, 0],
        direction: 'none',
        extensionsLength: [0, 0],
        legType: null,
        moduleLength: 620,
        type: 'base',
        sleepingMode: false,
        ottomanMode: false,
        storageMode: false,
        color: {},
        colors: {
          armrest: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
          backCushion: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
          cushion: ['diamante_anthracite_4045', 'diamante_anthracite_4045'],
        },
      },
    ],
  },
  nyce: {
    animation: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1200, 700, 133, 7.5, 203, 203),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    normal: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 166.25, 9.38, 253.75, 253.75),
      },
      processing: {
        noise: 0.1,
        samples: 250,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    mobile: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 50, 50),
      },
      processing: {
        noise: 0.1,
        samples: 250,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    hires: {
      camera: {
        fov: 40,
        angle: -30,
        vAngle: -15,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    thumbnail: {
      camera: {
        height: 74,
        width: 74,
        fov: 40,
        angle: -30,
        vAngle: -15,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
  },
  backUrl: '/couches-and-armchairs',
  maxSofaLength: 10000,
  label: 'SVG',
  moduleWithOneCushion: [620, 770, 1020],
  endWithOneCushion: [1360, 1620],
  furnitureType: 'pyllow',
  invalidUuidFallback: 'QiqQ19E8U',
  stage: 'default',
  tabs: {
    desktop: ['default'],
    mobile: ['modulesMobile', 'elementsMobile', 'colorsMobile', 'review'],
  },
  tabsNextText: ['To modules', 'To colors', 'To review'],
  // The folowing filter are to prevent technical specs from displaying on the website
  dimensionsFilters: joynconfig.dimensionsFilters,
  assemblyFeature: 7,
  productDetailText: 'About Our Pyllow Sofa',
  forbiddenExtensionModuleLength: [620],
  options: [
    // Module options
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '62 cm',
      length: 620,
      iconName: 'configurator/pyllow/module-620',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '77 cm',
      length: 770,
      iconName: 'configurator/pyllow/module-770',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '102 cm',
      length: 1020,
      iconName: 'configurator/pyllow/module-1020',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      sleepingMode: true,
      direction: 'none',
      text: 'Sleeping module',
      length: 2040,
      iconName: 'configurator/pyllow/sofa-bed-2040',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '34 cm',
      length: 340,
      iconName: 'configurator/pyllow/armrest-340',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '48 cm',
      length: 480,
      iconName: 'configurator/pyllow/armrest-480',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '34 cm',
      length: 340,
      iconName: 'configurator/pyllow/armrest-340',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '48 cm',
      length: 480,
      iconName: 'configurator/pyllow/armrest-480',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'left',
      text: '136 cm',
      length: 1360,
      iconName: 'configurator/pyllow/end-left-1360',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'left',
      text: '162 cm',
      length: 1620,
      iconName: 'configurator/pyllow/end-left-1620',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'right',
      text: '136 cm',
      length: 1360,
      iconName: 'configurator/pyllow/end-right-1360',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'edge',
      direction: 'right',
      text: '162 cm',
      length: 1620,
      iconName: 'configurator/pyllow/end-right-1620',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      text: 'To the left',
      length: 340,
      iconName: 'configurator/pyllow/lounge-left',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      text: 'To the left',
      length: 480,
      iconName: 'configurator/pyllow/lounge-left',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      text: 'To the right',
      length: 340,
      iconName: 'configurator/pyllow/lounge-right',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      text: 'To the right',
      length: 480,
      iconName: 'configurator/pyllow/lounge-right',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      direction: 'none',
      text: '107 cm',
      length: 1070,
      iconName: 'configurator/pyllow/corner-1070',
    },
    {
      action: 'remove',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'any',
      direction: 'none',
      text: 'Remove',
      length: 0,
      iconName: 'configurator/trash-bin',
    },
    // Cover color options
    ...flatten(
      Object.keys(joynconfig.covers).map(
        (category: string): Option =>
          joynconfig.covers[category].map(
            (color: string): Option => ({
              action: 'update',
              group: 'color',
              isDisabled: null,
              isSelected: null,
              message: '',
              type: 'cover',
              value: color,
              iconName: '',
              text: '',
            })
          )
      )
    ),
  ],
  dimensions: {
    armrestWidths: [340, 480],
    depth: 980, // normal base width
    depthWithExtension: 1620, // normal base + extension base width
  },
  defaultDimensions: {
    width: 0,
    length: 0,
    height: 720,

    center_length: 0,
    center_depth: 0,
    center_depth_withExt: 0,
    center_height: 0,

    left_length: 0,
    left_depth: 0,
    left_depth_withExt: 0,
    left_height: 0,

    right_length: 0,
    right_depth: 0,
    right_depth_withExt: 0,
    right_height: 0,
    seat_depth: 0,
    seat_depth_extension: 0,
    seat_height: 420,

    regular_depth: 1070,
    extension_depth: 1680,
    pouf_depth: 600,
    seat_width: 710,
    seat_width_extension: 1320,
  },
  viewModes: {
    modules: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    colorsWhole: {
      zoomMax: 1.8,
      subTabsName: ['colorsWhole', 'colorsCushionBackcushion'],
      iconName: 'configurator/joyn/color-all',
      selectionMode: 'all',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_whole',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: 'Whole Couch',
    },
    colors: {
      zoomMax: 1.8,
      subTabsName: ['colors', 'colorsWhole', 'colorsCushionBackcushion'],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colors',
      URLTabName: 'colors',
      text: '',
    },
    colorsMobile: {
      zoomMax: 1.8,
      subTabsName: ['colorsWhole', 'colorsCushionBackcushion'],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: '',
    },
    colorsCushionBackcushion: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Cushions & Armrests',
      iconName: 'configurator/joyn/color-cushions',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_cushion_backcushion',
    },
    elements: {
      zoomMax: 1.8,
      subTabsName: ['modules'],
      defaultSubTabName: 'modules',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: reviewCameraSetup,
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: baseCameraSetup,
      group: 'color',
      groupStructure: '',
      iconName: '',
      labelMode: 'color_whole',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: ['modules', 'colorsMobile'],
      text: '',
    },
    modulesMobile: {
      zoomMax: 1.8,
      subTabsName: ['seat-modules', 'lounge', 'corner-modules'],
      defaultSubTabName: 'seat-modules',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    elementsMobile: {
      zoomMax: 1.8,
      subTabsName: ['armrests'],
      defaultSubTabName: 'armrests',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    'seat-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    lounge: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    'corner-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    armrests: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
  },
  notifications: {},
  // components, which are define the primary colour
  // and weights to define priority (default weight is 1)
  primarySections: {
    base: 10,
    corner_backrest: 1,
    sofa_bed_left: 10,
    end_cushion_right: 10,
    armrest_left: 1,
    base_pouf: 1,
    sofa_bed: 10,
    sofa_bed_right: 10,
    corner: 10,
    backrest: 1,
    armrest_right: 1,
    l_cushion_right: 10,
    end_cushion_left: 10,
    l_cushion_left: 10,
  },
  basePoufLengthDiff: 0,
  basePoufWidthDiff: 0,
  poufWidthCM: 60,
  isOttoman: false,
  hadOttomanConfigurator: true,
  defaultExtensionDepth: 610,
};

export default config;
