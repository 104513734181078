import { PureComponent } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';
import Button from 'mycs/shared/components/Button/Button';
import styles from './Scrollable.scss';

interface Props {
  arrows?: boolean;
  centered?: boolean;
  children: any;
  wrap?: boolean;
  small?: boolean;
  currentIndex?: number;
}

interface State {
  isScrolling: boolean;
  canScrollLeft: boolean;
  canScrollRight: boolean;
}

export enum Direction {
  Left = 'left',
  Right = 'right',
}

/**
 * Scrollable container component
 *
 * @class Scrollable
 * @extends {PureComponent<*, *>}
 */
export default class Scrollable extends PureComponent<Props, State> {
  container: HTMLDivElement | null = null;
  scrollable: HTMLDivElement | null = null;

  static defaultProps = {
    arrows: false,
    wrap: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isScrolling: false,
      canScrollLeft: false,
      canScrollRight: true,
    };
  }

  /**
   * Check if the content is wider than the container
   */
  setScrolling(): void {
    if (this.props.wrap) return;
    let isScrolling = false;
    if (
      this.container &&
      this.scrollable &&
      this.container.offsetWidth < this.scrollable.scrollWidth
    )
      isScrolling = true;
    this.setState({ isScrolling });
  }

  /**
   * Check if it is possible to scroll left
   */
  checkLeftScroll = (): boolean =>
    this.scrollable !== null && this.scrollable.scrollLeft > 0;

  /**
   * Check if it is possible to scroll right
   */
  checkRightScroll = (): boolean => {
    return (
      this.scrollable !== null &&
      this.container !== null &&
      this.scrollable.scrollWidth - this.container.offsetWidth >
        this.scrollable.scrollLeft
    );
  };

  updateScrollStates = (): void => {
    this.setState({
      canScrollLeft: this.checkLeftScroll(),
      canScrollRight: this.checkRightScroll(),
    });
  };

  /**
   * Deactivate the arrows if necessary
   * after scrolling manually
   */
  onScroll = debounce((): void => {
    this.updateScrollStates();
  }, 50);

  scrollInDirection = (direction: Direction): void => {
    if (!this.container || !this.scrollable) return;

    let scrollAmount = 0;
    let canScroll = false;
    const scrollPadding = 100;

    if (direction === Direction.Left) {
      canScroll = this.checkLeftScroll();
      scrollAmount =
        this.scrollable.scrollLeft - this.container.offsetWidth - scrollPadding;
    } else {
      canScroll = this.checkRightScroll();
      scrollAmount =
        this.scrollable.scrollLeft + this.container.offsetWidth - scrollPadding;
    }

    if (canScroll) {
      this.scrollTo(scrollAmount);
    }
  };

  getOutOfBoundDirection(
    parentRect: DOMRect,
    childRect: DOMRect,
    offset = 0
  ): Direction | null {
    const childLeft = childRect.left + offset;
    const childRight = childRect.right - offset;
    const outOfBoundLeft = parentRect.left >= childRight;
    const outOfBoundRight = parentRect.right <= childLeft;

    if (outOfBoundRight) return Direction.Right;
    if (outOfBoundLeft) return Direction.Left;

    return null;
  }

  scrollToChildIfOutOfBound = (index: number): void => {
    const { children } = this.props;
    const childToScrollTo = children[index];

    if (!this.container) return;

    const parentRect = this.container.getBoundingClientRect();

    if (!childToScrollTo.ref || !childToScrollTo.ref.current) return;

    const childRect = childToScrollTo.ref.current.getBoundingClientRect();
    const childPadding = 5;
    const outOfBoundOffset = childRect.width + childPadding;
    const outOfBoundDirection = this.getOutOfBoundDirection(
      parentRect,
      childRect,
      outOfBoundOffset
    );
    if (outOfBoundDirection) {
      if (!childToScrollTo.ref || !childToScrollTo.ref.current?.offsetLeft)
        return;

      const parentWidth = parentRect.width;
      const childWidth = childRect.width;
      this.displayFullRow(
        outOfBoundDirection,
        childToScrollTo.ref.current.offsetLeft,
        childWidth,
        parentWidth
      );
    }
  };

  displayFullRow = (
    direction: Direction,
    childToScrollToOffset: number,
    childWidth: number,
    parentWidth: number
  ): void => {
    const childrenInRow = parentWidth / childWidth;
    const reminder = childrenInRow - Math.floor(childrenInRow);
    const offset = (childWidth * reminder) / 2;

    switch (direction) {
      case Direction.Left:
        // Substract current child from children in Row
        const scrollLeftAmount =
          childToScrollToOffset - (childrenInRow - 1) * childWidth;
        this.scrollTo(scrollLeftAmount + offset);
        break;
      case Direction.Right:
        this.scrollTo(childToScrollToOffset - offset);
        break;
    }
  };

  /**
   * Scroll programmatically
   */
  scrollTo = (scrollOffset: number): void => {
    if (!this.scrollable) return;
    UIUtils.animate(this.scrollable.scrollLeft, scrollOffset, 100).subscribe(
      (value) => {
        if (this.scrollable) this.scrollable.scrollLeft = value;
        this.updateScrollStates();
      }
    );
  };

  /**
   * Init the arrows if required
   */
  componentDidMount(): void {
    this.setScrolling();
  }

  /**
   * Update the arrows if the amount of children has changed
   */
  componentDidUpdate(prevProps: Props): void {
    if (
      (this.props.currentIndex || this.props.currentIndex === 0) &&
      this.props.currentIndex !== prevProps.currentIndex
    ) {
      this.scrollToChildIfOutOfBound(this.props.currentIndex);
    }
    if (!isEqual(this.props.children, prevProps.children)) {
      this.setScrolling();
    }
  }

  render(): React.ReactNode {
    const { children, arrows, centered, wrap, small } = this.props;
    const { isScrolling, canScrollLeft, canScrollRight } = this.state;

    return (
      <div
        className={classNames(styles.container, {
          [styles.centered]: !isScrolling && centered,
          [styles.withArrows]: isScrolling && arrows,
          [styles.wrap]: wrap,
          [styles.small]: small,
        })}
        ref={(node) => (this.container = node)}
      >
        <div
          className={styles.scrollable}
          ref={(node) => (this.scrollable = node)}
          onScroll={this.onScroll}
        >
          {children}
        </div>

        <Button
          iconName="general/arrow-chevron-left"
          onClick={() => this.scrollInDirection(Direction.Left)}
          className={classNames(styles.arrow, styles.left)}
          isDisabled={!canScrollLeft}
        />

        <Button
          iconName="general/arrow-chevron-right"
          onClick={() => this.scrollInDirection(Direction.Right)}
          className={classNames(styles.arrow, styles.right)}
          isDisabled={!canScrollRight}
        />
      </div>
    );
  }
}
