import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import MetaConversionsAPIService from 'mycs/shared/services/AnalyticsService/MetaConversionsAPIService';
import Logger from 'mycs/shared/services/Logger';
import { useUser } from 'mycs/hooks/useUser';
import FlagModeService from 'mycs/shared/services/FlagModeService/FlagModeService';
import GeolocationService from 'mycs/shared/services/GeolocationService/GeolocationService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import ReportingAPIService from 'mycs/shared/services/AnalyticsService/ReportingAPIService';
import { useCookie } from 'mycs/shared/state/CookieContext';
import { useCookieConsent } from 'mycs/hooks/useCookieConsent';

export function useAnalyticsTools() {
  const { countryCode, locale } = useLocale();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const prevLocationRef = useRef<typeof location>();
  const prevPathNameRef = useRef<string>();
  const { user, isLoading } = useUser();
  const { cookieConsent } = useCookieConsent();
  const [experimentVariant = '0'] = useCookie('X-Experiment');
  const [, setMoeclidCookie] = useCookie('moeclid');

  // Pageview tracker.
  useEffect(() => {
    if (pathname === prevLocationRef.current?.pathname) {
      return;
    }
    const meta = {
      countryCode,
      locale,
      locationPathname: pathname,
      urlSearchParams: new URLSearchParams(search),
      documentReferrerURL: document.referrer
        ? new URL(document.referrer)
        : undefined,
      user,
      isNoTrack: FlagModeService.isNoTrack(),
      isShowroom: FlagModeService.isShowroom(),
      locationData: GeolocationService.getLocationDataCache(),
    };
    // Send to Reporting API
    const onsiteEvent = AnalyticsService.getPageviewEvent('Onsite', meta);
    ReportingAPIService.handleEvent(onsiteEvent);

    Logger.log({
      // Strip out sensitive data.
      ...onsiteEvent,
      mycsUserEmail: '[REDACTED]',
    });

    // Push to Google Analytics
    const gaEvent = AnalyticsService.getPageviewEvent('GA', meta);
    window.gtag(gaEvent);

    prevLocationRef.current = location;
  }, [countryCode, locale, pathname, search, user]);

  useEffect(() => {
    // Sending when user visits each page for the first time and the user data has been loaded
    if (!isLoading && prevPathNameRef.current !== pathname) {
      MetaConversionsAPIService.setUserData(user);
      MetaConversionsAPIService.setCountry(countryCode);
      MetaConversionsAPIService.setLocale(locale);
      MetaConversionsAPIService.eventTrack('PageViewAPI');
      prevPathNameRef.current = pathname;
    }
  }, [user, countryCode, isLoading, pathname]);

  // moebel.de sales tracking
  // https://partner-integration.moebel.de/sales-tracking/1/manual-server-side-integration.html
  useEffect(() => {
    if (!cookieConsent?.marketing) {
      return;
    }

    const moeclid = searchParams.get('moeclid');
    if (moeclid) {
      setMoeclidCookie(moeclid, 7776000); // 90 days
    }
  }, [cookieConsent?.marketing, searchParams, setMoeclidCookie]);

  // TODO We should probably extend
  // the existing events instead.
  useEffect(() => {
    AnalyticsService.setUserData(user);
  }, [user]);

  useEffect(() => {
    // X-Experiment belongs to "statistics" cookies.
    if (cookieConsent?.statistics) {
      AnalyticsService.setExperimentVariant(parseInt(experimentVariant));
    }
  }, [cookieConsent?.statistics, experimentVariant]);
}
