import { createContext, useContext, useState, ReactNode } from 'react';

export interface IsMenuOpenContextType {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const IsMenuOpenContext = createContext<
  IsMenuOpenContextType | undefined
>(undefined);

export function IsMenuOpenProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(
    false
  );
  return (
    <IsMenuOpenContext.Provider
      value={{ isMenuOpen, setIsMenuOpen }}
    >
      {children}
    </IsMenuOpenContext.Provider>
  );
}

export function useIsMenuOpen(): IsMenuOpenContextType {
  const context = useContext(IsMenuOpenContext);
  if (!context) {
    throw new Error(
      'useIsMenuOpen must be used within an IsMenuOpenProvider'
    );
  }
  return context;
}
