import { PureComponent } from 'react';
import classNames from 'classnames';
import { CfButtonLink } from '@mycs/contentful';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';

import styles from './Alert.scss';
import { useIsShareDesignOpen } from '../App/context/IsShareDesignOpenContext';

type Props = {
  text?: string;
  className?: string;
  iconName?: string;
  children?: React.ReactNode;
  iconPosition?: number;
  closeable?: boolean;
  close?: () => void;
  timeout?: number;
  isInfoMessage?: boolean;
  button?: CfButtonLink;
  isLeftAligned?: boolean;
  hideForeverId?: string;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
};

type State = {
  status: string;
  isHidden: boolean;
};

export default function Alert(props: Props) {
  const { locale } = useLocale();
  const { isShareDesignOpen } = useIsShareDesignOpen();

  return (
    <InnerAlert
      {...props}
      locale={locale}
      isShareDesignOpen={isShareDesignOpen}
    />
  );
}

type InnerProps = Props & {
  locale: string;
  isShareDesignOpen?: boolean;
};

class InnerAlert extends PureComponent<InnerProps, State> {
  autoTimeout: any;
  manualTimeout: any;

  static defaultProps = {
    text: '',
    className: '',
    timeout: 0,
    close: noop,
    isInfoMessage: false,
    isLeftAligned: false,
  };

  constructor(props: InnerProps) {
    super(props);
    this.state = {
      status: 'opened',
      isHidden: false,
    };
  }

  /**
   * Close the notification (once closed, it shouldn't open again)
   */
  closeNotification = () => {
    if (['closed', 'closing'].includes(this.state.status)) {
      return;
    }
    // Manage the hiding animation of the component
    this.setState({ status: 'closing' }, () => {
      this.manualTimeout = setTimeout(() => {
        this.props.close?.();
        this.setState({ status: 'closed' });
      }, 400);
    });
  };

  handleClickOutside = () => {
    const { closeable } = this.props;
    if (closeable) {
      this.closeNotification();
    }
  };

  renderCustom(): React.ReactNode {
    const {
      className,
      text,
      children,
      iconName,
      closeable,
      iconPosition,
      isInfoMessage,
      isLeftAligned,
      onTouchStart,
      onTouchEnd,
    } = this.props;
    const { status } = this.state;
    const textArr = I18nUtils.localize(this.props.locale, text).split(' ');
    const text1 = textArr.slice(0, iconPosition).join(' ');
    const text2 = textArr.slice(iconPosition).join(' ');

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <div
          className={classNames(styles.alert, className, {
            [styles.closeable]: closeable,
            [styles.closing]: closeable && status === 'closing',
            [styles.opened]: closeable && status === 'opened',
            [styles.infoMessage]: isInfoMessage,
            [styles.leftAligned]: isLeftAligned,
            [styles.shareDesign]: this.props.isShareDesignOpen,
          })}
          onClick={closeable ? this.closeNotification : () => {}}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        >
          <div className={styles.textContainer}>
            {text1 ? <span className={styles.text}>{text1}</span> : null}

            {iconName ? (
              <div className={styles.iconContainer}>
                <Icon iconName={iconName} className={styles.icon} />
              </div>
            ) : null}

            {text2 ? <span className={styles.text}>{text2}</span> : null}

            {children}
          </div>

          {closeable ? (
            <Button
              className={styles.iconCloseContainer}
              iconContainerClass={styles.iconClose}
              iconName="general/close"
            />
          ) : null}
        </div>
      </ClickOutside>
    );
  }

  renderDefault() {
    const {
      locale,
      className,
      text,
      children,
      iconName,
      closeable,
      isInfoMessage,
      button,
      isLeftAligned,
      onTouchStart,
      onTouchEnd,
    } = this.props;

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <div
          className={classNames(styles.alert, className, {
            [styles.closeable]: closeable,
            [styles.closing]: closeable && this.state.status === 'closing',
            [styles.opened]: closeable && this.state.status === 'opened',
            [styles.closed]: this.state.status === 'closed',
            [styles.infoMessage]: isInfoMessage,
            [styles.leftAligned]: isLeftAligned,
            [styles.shareDesign]: this.props.isShareDesignOpen,
          })}
          onClick={closeable ? this.closeNotification : () => {}}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        >
          {iconName ? (
            <div className={styles.iconContainer}>
              <Icon iconName={iconName} className={styles.icon} />
            </div>
          ) : null}
          {!this.props.isShareDesignOpen && (
            <span className={styles.text}>
              {I18nUtils.localize(locale, text)}
            </span>
          )}

          {button && (
            <div className={styles.buttonContainer}>
              <Button
                href={button.url}
                text={button.text}
                className={styles.button}
                isFlatSecondaryCta
                onClick={this.onButtonClick}
              />
            </div>
          )}

          {children}

          {closeable ? (
            <div className={styles.iconCloseContainer}>
              <Icon className={styles.iconClose} iconName="general/close" />
            </div>
          ) : null}
        </div>
      </ClickOutside>
    );
  }

  /**
   * Hide the info message based on the local storage value passed via prop
   * (Once hidden, it shouldn't be displayed again)
   */
  onButtonClick() {
    const { hideForeverId } = this.props;
    if (!hideForeverId) return;

    this.setState(
      {
        isHidden: true,
      },
      () => {
        LocalStorageUtils.set(hideForeverId, 1);
      }
    );
  }

  /**
   * Check if the info message has been viewed and dismissed
   */
  checkInfoMessageDismissed() {
    const { hideForeverId } = this.props;
    if (!hideForeverId) return;

    this.setState({
      isHidden: Boolean(LocalStorageUtils.get(hideForeverId)),
    });
  }

  /**
   * On mounting
   */
  componentDidMount() {
    const { timeout } = this.props;

    if (timeout) {
      this.autoTimeout = setTimeout(() => {
        this.setState({ status: 'closed' });
      }, timeout);
    }

    this.checkInfoMessageDismissed();
  }

  /**
   * On update
   */
  componentDidUpdate() {
    this.checkInfoMessageDismissed();
  }

  /**
   * Cleanup on un-mounting
   */
  componentWillUnmount() {
    if (this.manualTimeout) {
      clearTimeout(this.manualTimeout);
    }
    if (this.autoTimeout) {
      clearTimeout(this.autoTimeout);
    }
  }

  render() {
    if (this.state.isHidden) return null;

    return this.props.isShareDesignOpen || !this.props.iconPosition
      ? this.renderDefault()
      : this.renderCustom();
  }
}
