import { useDevice } from "mycs/router/DeviceContext";
import { toast, Slide, ToastContainer as ToastifyContainer, } from "react-toastify";
import { useIsShareDesignOpen } from "../App/context/IsShareDesignOpenContext";
import ClickOutside from "../ClickOutside/ClickOutside";
import classNames from "classnames";
import styles from "./ToastContainer.scss";

export function ToastContainer() {
  const { hasPhoneDimensions } = useDevice();
  const {isShareDesignOpen} = useIsShareDesignOpen()

  return (
    <ClickOutside onClickOutside={() => toast.dismiss()}>
      <ToastifyContainer
        transition={Slide}
        newestOnTop={hasPhoneDimensions}
        limit={1}
      />
    </ClickOutside>
  );
}