import { useMemo } from 'react';

const disabledRoutesFooter = ['/closed-store'];
const disabledRoutesHeader = ['/closed-store'];

const disablePartRoutesFooter = ['/instruction'];
const disablePartRoutesHeader = ['/instruction'];

export default function useUI(location: string) {
  const showFooter = useMemo(
    () => !disabledRoutesFooter.includes(location) &&
      !disablePartRoutesFooter.find((route) => location.includes(route)),
    [location]
  );
  const showHeader = useMemo(
    () => !disabledRoutesHeader.includes(location) &&
      !disablePartRoutesHeader.find((route) => location.includes(route)),
    [location]
  );
  return {
    showHeader,
    showFooter,
  };
}
