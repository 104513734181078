import { Component } from 'react';
import classNames from 'classnames';
import { CfHeaderSubmenu } from '@mycs/contentful';
import { getHeaderFromByLocaleReduxState } from 'mycs/shared/state/slices/headersSlice';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import Localize from 'mycs/shared/components/Localize/Localize';
import NavigationMenuGroups from 'mycs/shared/components/NavigationMenuGroups/NavigationMenuGroups';
import styles from './NavigationMenuOverlay.scss';
import { useIsMenuOpen } from '../App/context/IsMenuOpenContext';

export interface NavigationMenuOverlayProps {
  isMobileConfigurator?: boolean;
}

export default function NavigationMenuOverlay({
  isMobileConfigurator,
}: NavigationMenuOverlayProps) {
  const { locale } = useLocale();
  const { setIsMenuOpen } = useIsMenuOpen();

  const data = useAppSelector(
    (state) =>
      getHeaderFromByLocaleReduxState(state, locale)?.mobileSubmenus ?? []
  );

  return (
    <InnerNavigationMenuOverlay
      menu={data}
      isMobileConfigurator={isMobileConfigurator}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
}

interface Props {
  menu: CfHeaderSubmenu[];
  isMobileConfigurator?: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

interface State {
  menuStatus: string;
}

class InnerNavigationMenuOverlay extends Component<Props, State> {
  closeTimeout: any;

  static defaultProps = {
    menu: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      menuStatus: 'closed',
    };
  }

  /**
   * Toggle the page scroll
   */
  toggleScroll(toggle: boolean) {
    // Some elements in the configurator are displayed above the overlay (pull menu, bottom menu)
    const configuratorElement = document.getElementById(
      'configurator-container'
    );

    if (toggle) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.removeEventListener('touchmove', this.onScroll);

      if (configuratorElement) {
        configuratorElement.style.position = '';
      }
    } else {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.addEventListener('touchmove', this.onScroll, false);

      if (configuratorElement) {
        configuratorElement.style.position = 'fixed';
      }
    }
  }

  onScroll = (e: any) => {
    e.preventDefault();
  };

  /**
   * On menu item selection
   */
  onSelect = () => {
    this.setState({ menuStatus: 'closed' });
    this.toggleScroll(true);
  };

  /**
   * On menu toggle
   */
  onToggle = () => {
    const isOpened = this.state.menuStatus === 'opened';
    if (isOpened) {
      this.setState({ menuStatus: 'closing' }, () => {
        this.closeTimeout = setTimeout(() => {
          this.setState({ menuStatus: 'closed' });
          this.props.setIsMenuOpen(false);
        }, 300);
      });
    } else {
      this.setState({ menuStatus: 'opened' });
      this.props.setIsMenuOpen(true);
    }
    this.toggleScroll(isOpened);
  };

  /**
   * On click outside
   */
  onClose = () => {
    const isOpened = this.state.menuStatus === 'opened';
    if (!isOpened) return;
    this.onToggle();
  };

  /**
   * On destroy
   */
  componentWillUnmount() {
    this.toggleScroll(true);
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  render() {
    const statusClasses = {
      [styles.closed]: this.state.menuStatus === 'closed',
      [styles.closing]: this.state.menuStatus === 'closing',
      [styles.opened]: this.state.menuStatus === 'opened',
    };
    const menuOverlayClasses = classNames(styles.menuOverlay, statusClasses);
    const menuClasses = classNames(styles.menu, statusClasses);
    const menuGroups = this.props.menu || [];

    return (
      <div className={styles.container}>
        <div
          className={classNames(styles.menuButton, {
            [styles.mobileConfigurator]: this.props.isMobileConfigurator,
          })}
          onClick={this.onToggle}
        >
          <div>
            <div className={styles.menuButtonIcon}></div>
            <div className={styles.menuButtonIcon}></div>
            <div className={styles.menuButtonIcon}></div>
          </div>
          {!this.props.isMobileConfigurator && (
            <div className={styles.menuButtonText}>
              <Localize>Menu</Localize>
            </div>
          )}
        </div>
        <div className={menuOverlayClasses}>
          <ClickOutside onClickOutside={this.onClose}>
            <div className={menuClasses}>
              <div className={styles.scrolling}>
                <NavigationMenuGroups
                  groups={menuGroups}
                  onSelectActiveItem={this.onSelect}
                  onClose={this.onClose}
                />
              </div>
            </div>
          </ClickOutside>
        </div>
      </div>
    );
  }
}
