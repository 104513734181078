import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import config from 'mycs/config';
import { User } from 'mycs/api/UserAPI';
import MetaConversionsAPIService from 'mycs/shared/services/AnalyticsService/MetaConversionsAPIService';

enum EventType {
  NewLead = 'newLead',
  RetrieveDesigns = 'retrieveDesigns',
  ZendeskForm = 'zendeskForm',
  NewsletterSubscription = 'newsletterSubscription',
  signUp = 'signUp',
}

interface Event {
  locale: string;
  countryCode: string;
  user: User;
  componentPath?: string;
  campaignName?: string;
  subject?: string;
}

interface Tag {
  event: EventType;
  pageCountry: string;
  loginUserId: number;
  loginUserEmail: string;
  langCode: string;
  subject?: string;
  componentPath?: string;
  campaignName?: string;
}

const { userID: userIDCookie, email: emailLocalKey } =
  config.storageSettings.keys;
const showroomCookie = config.flagModes.showroom.cookie;
const showroomKeyPrefix = `${showroomCookie}-`;
function isShowroom() {
  return Boolean(LocalStorageUtils.getCookie(showroomCookie));
}

function buildTag(
  event: EventType,
  { locale, countryCode, user, componentPath, campaignName, subject }: Event
): Tag {
  return {
    event,
    loginUserId: user.id,
    loginUserEmail: user.email,
    pageCountry: countryCode.toUpperCase(),
    langCode: locale,
    componentPath,
    campaignName,
    subject,
  };
}

export function trackNewLeadEvent({
  locale,
  countryCode,
  user,
  componentPath,
}: Event) {
  if (isShowroom()) {
    LocalStorageUtils.setCookie(showroomKeyPrefix + userIDCookie, user.id);
    LocalStorageUtils.set(showroomKeyPrefix + emailLocalKey, user.email);
  }

  const tag = buildTag(EventType.NewLead, {
    locale,
    countryCode,
    user,
    componentPath,
  });
  AnalyticsService.eventTrack(tag.event, locale, tag);
}

export function trackRetrieveDesignsEvent({
  locale,
  countryCode,
  user,
}: Event) {
  const tag = buildTag(EventType.RetrieveDesigns, {
    locale,
    countryCode,
    user,
  });
  AnalyticsService.eventTrack(tag.event, locale, tag);
}

export function trackZendeskFormEvent({
  locale,
  countryCode,
  user,
  subject,
}: Event) {
  const tag = buildTag(EventType.ZendeskForm, {
    locale,
    countryCode,
    user,
    subject,
  });
  AnalyticsService.eventTrack(tag.event, locale, tag);
}

export function trackNewsletterSubEvent({
  locale,
  countryCode,
  user,
  componentPath,
}: Event) {
  const tag = buildTag(EventType.NewsletterSubscription, {
    locale,
    countryCode,
    user,
    componentPath,
  });
  AnalyticsService.eventTrack(tag.event, locale, tag);
  //Setting userdata here additionally to prevent sending an empty object
  MetaConversionsAPIService.setUserData(user);
  MetaConversionsAPIService.eventTrack("LeadAPI");
}

export function trackSignUp(userID: number, userToken: string) {
  // It's newer event, so it doesn't have the same flow.
  window.dataLayer.push({
    event: EventType.signUp,
    userID,
    userToken,
  });
}
